import Vue from 'vue';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import VueTimers from 'vue-timers';
Vue.use(VueTimers);

Vue.use(require('vue-moment'));

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  fractionCount: 2,
  thousandsSeparator: ',',
  fractionSeparator: '.',
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
