<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-4">
        <span class="fs-2 text-white">
          {{ currentTime | moment("h:mm:ss A") }}
        </span>
      </div>

      <div class="col-3">
        <select
          class="form-select custom-select"
          aria-label="Default select example"
          v-model="refreshInterval"
        >
          <option selected :value="60000">Refresh every minute</option>
          <option :value="300000">Refresh every 5 Minutes</option>
          <option :value="600000">Refresh every10 Minutes</option>
        </select>
      </div>
      <div class="col-2">
        <button class="btn btn-success" @click="loadData">
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
      <div class="col-3">
        <div class="btn-group" role="group" aria-label="Title Font Group">
          <button
            @click="titleFontSize = titleFontSize + 0.5"
            type="button"
            class="btn btn-outline-success"
          >
            T<i class="bi bi-arrow-up-short"></i>
          </button>
          <button
            @click="titleFontSize = titleFontSize - 0.5"
            type="button"
            class="btn btn-outline-success"
          >
            T<i class="bi bi-arrow-down-short"></i>
          </button>
        </div>
        <div
          class="btn-group"
          style="margin-left: 10px"
          role="group"
          aria-label="Value Font Group"
        >
          <button
            @click="valueFontSize = valueFontSize + 0.5"
            type="button"
            class="btn btn-outline-success"
          >
            V<i class="bi bi-arrow-up-short"></i>
          </button>
          <button
            @click="valueFontSize = valueFontSize - 0.5"
            type="button"
            class="btn btn-outline-success"
          >
            V<i class="bi bi-arrow-down-short"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        v-for="(url, index) in urls"
        :key="index"
        class="col-sm-4 col-lg-3 col-md-6"
      >
        <div class="tile">
          <h3
            class="title fw-bold"
            :style="{ 'font-size': titleFontSize + 'rem' }"
          >
            {{ url.title }}
          </h3>
          <span
            :class="
              url.isSuccess
                ? 'badge bg-success last-updated'
                : 'badge bg-danger last-updated'
            "
          >
            {{ updateDate | moment("h:mm a") }}
          </span>
          <p
            class="api-value mt-4 text-center text-truncate"
            :style="{ 'font-size': valueFontSize + 'rem' }"
          >
            <span v-html="url.prefix"></span>
            <span v-if="url.prefix.includes('$')">
              {{ url.value | currency }}</span
            >
            <span v-else>{{ url.value }}</span>
            <span v-html="url.suffix"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var jp = require("jsonpath");

export default {
  name: "App",
  timers: {
    loadData: { time: 60000, autostart: true, repeat: true },
    updateTime: { time: 1000, autostart: true, repeat: true },
  },
  data() {
    return {
      updateDate: null,
      currentTime: new Date(),
      urls: [],
      refreshInterval: 60000,
      titleFontSize: 1.75,
      valueFontSize: 3.5,
    };
  },
  methods: {
    updateTime() {
      this.currentTime = new Date();
    },
    loadData() {
      //Update update time
      this.updateDate = new Date();

      //for every API, fetch the result and update the tile
      this.urls.map((u) => {
        fetch(u.url)
          .then((resp) => resp.json())
          .then((data) => {
            if (u.jsonPath && u.jsonPath != "") {
              try {
                let result = jp.query(data, u.jsonPath);

                if (result && result.length > 0) {
                  //check if any tranformation required
                  if (u.transform) {
                    //Create a transformation function from the transformation config value
                    let transFunc = new Function("a", u.transform);
                    let temp = transFunc(result[0]);
                    u.value = temp;
                  } else {
                    u.value = result[0];
                  }
                  //Status for badge
                  u.isSuccess = true;
                }
              } catch (error) {
                u.isSuccess = false;
                console.log(
                  "🚀 ~ file: App.vue ~ line 130 ~ .then ~ error",
                  error
                );
              }
            }
          });
      });
    },
  },
  mounted() {
    //fetch config json
    fetch("config.json")
      .then((resp) => resp.json())
      .then((data) => {
        //set urls from config
        this.urls = data.urls;

        //Load initial settings from json
        this.refreshInterval = data.refreshInterval;
        this.titleFontSize = data.titleFontSize;
        this.valueFontSize = data.valueFontSize;

        //Update refresh time from config
        this.timers.loadData.time = data.refreshInterval;

        //restart timer to take effect
        this.$timer.start("loadData");

        //load data for the first time
        this.loadData();
      });
  },
  watch: {
    refreshInterval: function (newVal) {
      console.log("🚀 Changing refresh interval to ", newVal);
      //Update refresh time from config
      this.timers.loadData.time = newVal;

      //restart timer to take effect
      this.$timer.start("loadData");
    },
  },
};
</script>

<style>
body {
  background-color: #000 !important;
}

.tile {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 30px;
  margin-top: 0px;
  background: #203354;
  color: #fff;
  height: 250px;
}

.title {
  font-weight: 300;
}

.content {
  margin-top: 20%;
  font-size: 40px;
}

.tile:hover {
  background: #1e2357;
}

.last-updated {
  font-size: 8px !important;
}

.api-value {
  font-size: 3.5rem;
}

.custom-select {
  background-color: #000 !important;
  color: #fff !important;
}
</style>

